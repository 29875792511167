import "core-js/stable";
import "regenerator-runtime/runtime";
import '../css/style.css';
import dictionaryTable from './dictionary.json';
import logoImage from '../img/logo.svg';
import './sendBeacon.js';

/**
 * Helper for document.getElementById
 * @param {string} id 
 * @returns {HTMLElement}
 */
const getElementById = function (id) { return document.getElementById(id); };
/**
 * Helper for document.createElement
 * @param {string} name 
 * @param {string} ns 
 * @returns {HTMLElement}
 */
const createElement = function (name, ns) { return ns ? document.createElementNS(ns, name) : document.createElement(name); };
const endpoint = API_URL;
const screenRect = { width: 0, height: 0, diagonal: 0 };
const logo = getElementById('lgo');
const hero = getElementById('a');
const events = [];
const introHead = getElementById('c'); 
const introPara = getElementById('d'); 
const buildBtn = getElementById('b');
const colors = [0, '#2da0da', '#d71957', '#e69700', '#30276a', '#368033', '#2ba68b', '#d64545', '#00609c'];
const scrims = [];
const stages = [];
const requestData = [];
const formData = {};
const progressArr = buildProgress();
const progress = progressArr[0];
const progressTitle = progressArr[1];
const progressLeft = progressArr[2];
const progressRight = progressArr[3];
const pathdata = {
    f: "M300,461.4c-15.9,0-28.8-12.9-28.8-28.8s12.9-28.8,28.8-28.8c15.9,0,28.8,12.9,28.8,28.8S315.9,461.4,300,461.4zM331.3,171.3l-8.3,176c0,12.7-10.3,23.1-23.1,23.1s-23.1-10.3-23.1-23.1l-8.3-175.9v-0.1c-0.7-17.3,12.7-31.9,30-32.7c17.3-0.7,31.9,12.7,32.7,30C331.3,169.5,331.3,170.4,331.3,171.3L331.3,171.3z",
    s: "M456.1,199.5L262.3,430.2c-4.3,5.1-10.6,8.1-17.3,8.2h-0.4c-6.5,0-12.8-2.8-17.1-7.6l-83.1-92.3c-8.7-9.3-8.3-23.9,1-32.6c9.3-8.7,23.9-8.3,32.6,1c0.2,0.3,0.5,0.5,0.7,0.8l65.3,72.6l176.8-210.4c8.3-9.6,22.9-10.7,32.6-2.4C462.8,175.6,464,189.8,456.1,199.5L456.1,199.5z",
};
const heroImage = {
    'jpg': require('../img/cover.jpg'),
    'webp': require('../webp/cover.jpg.webp'),
};
const sectionImages = [
    [require('../img/sec-1-fg.png'), require('../img/sec-1-bg.png')],
    [require('../img/sec-2-fg.png'), require('../img/sec-2-bg.png')],
    [require('../img/sec-3-fg.png'), require('../img/sec-3-bg.png')],
    [require('../img/sec-4-fg.png'), require('../img/sec-4-bg.png')],
    [require('../img/sec-5-fg.png'), require('../img/sec-5-bg.png')],
    [require('../img/sec-6-fg.png'), require('../img/sec-6-bg.png')],
];
const introCopy = [
    ["Get a free review of your app, marketing & more.", "Get a free review of your brand, marketing & more.", "Get a free review of your marketing and more.", "Get a free review of your website and marketing."],
    ["We’re offering a complete review of your marketing mix, so you can maximise sales, get more installs, and build engagement. It’s totally free and customised to your specific needs.", "We’re offering a complete review of your marketing mix, so you can maximise sales, win more clients, and build a following. It’s totally free and customised to your specific needs."],
    ["BUILD MY HEALTH CHECK","BUILD MY REPORT","GET STARTED"],
];
const introVersions = {
    "a": [2,1,1],
    "b": [2,1,2],
    "c": [2,1,0],
    "d": [3,1,2],
    "e": [1,1,1],
    "f": [0,0,1],
    "g": [3,1,1],
    "h": [3,1,0],
    "z": [2,1,0],
};
const marketingSequence = [0, 4, 6, 1, 2, 3, 5, 7, 8];
const webSequence       = [0, 2, 3, 6, 4, 1, 5, 7, 8];
const brandSequence     = [0, 1, 6, 4, 2, 3, 5, 7, 8];
const appSequence       = [0, 3, 4, 6, 2, 1, 5, 7, 8];
const stageSequences = {
    "a": marketingSequence,
    "b": marketingSequence,
    "c": marketingSequence,
    "d": webSequence,
    "e": brandSequence,
    "f": appSequence,
    "g": webSequence,
    "h": webSequence,
    "z": [0, 1, 2, 3, 4, 5, 6, 7, 8],
};

let stageNum = 0;
let step = 0;
let apiToken;
let submittedTime = 0;
let isWebPSupported = false;
let cConsent = false;
let introVersion = "z";
let stageSquence = [];

/**
 * Main execution function
 */
function main() {
    if(!hasFeatures()) {
        const container = hero.parentElement;
        const root = container.parentElement;

        container.className = 'un';
        container.innerHTML = '<h1>Browser not supported</h1><p>To use this site, we recommend using the latest version of Chrome, Firefox, Safari or Edge.</p>';
        while(root.lastChild != container) root.removeChild(root.lastChild);
        return;
    }
    
    // Add event beacon for page open
    addEvent('op');
    getUrlParameters();
    
    // Setup listeners for events
    window.addEventListener('load', eventInit);
    window.addEventListener('resize', measureScreen);
    document.addEventListener('visibilitychange', sendEvents);
}

/**
 * Load options from the url query string
 */
function getUrlParameters() {
    const params = new URLSearchParams(window.location.search);
    if(params.has('k')) {
        // Add Keyword to event beacon
        addEvent('ky', params.get('k'));
    }
    if(params.has('v')) {
        const paramVersion = params.get('v').toLowerCase();
        if(introVersions[paramVersion]) introVersion = paramVersion;
        addEvent('vr', introVersion);
    }
}

/**
 * Display intro text
 */
function initIntro() {
    const headline = introVersions[introVersion][0];
    const bodyText = introVersions[introVersion][1];
    const btnLabel = introVersions[introVersion][2];
    stageSquence = (stageSequences[introVersion]) ? stageSequences[introVersion] : stageSequences.z;
    introHead.innerText = introCopy[0][headline];
    introPara.innerText = introCopy[1][bodyText];
    buildBtn.innerText = introCopy[2][btnLabel];
    introHead.parentElement.classList.remove('hide');
}

/**
 * Page loaded event listener
 */
async function eventInit() {
    // initLinkedin();
    initIntro();
    isWebPSupported = await testWebp();
    const logoLoaded = await loadImage(logoImage);
    logo.style.backgroundImage = `url(${logoLoaded.src})`;
    logo.children[0].style.visibility = 'hidden';
    addEvent('ld');
    fetchToken();
    // Build scrims
    for (let i = 1; i < 9; i++) {
        scrims[i] = buildScrim(colors[i]);
    }
    measureScreen();
    buildBtn.addEventListener('click', beginBuild);
    eventLoaded();
}

/**
 * Get the ID Token from server
 * @returns {boolean}
 */
async function fetchToken() {
    try {
        apiToken = await getHttpRequest({id:0});
        return true;
    } catch (e) {
        console.error(e);
    }
    return false;
}

/**
 * Issue an XMLHttpRequest sending a JSON object
 * @param {object} data 
 * @returns {Promise<string>}
 */
function getHttpRequest(data) {
    if(!data) data = {};
    return new Promise(function (resolve, reject) {
        const req = new XMLHttpRequest();
        req.open('POST', endpoint, true);
        req.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        req.setRequestHeader('Accept', 'application/json');
        req.onload = function (e) {
            if (req.readyState === 4) {
                if (req.status === 200) {
                    resolve(req.responseText);
                } else {
                    reject(req.statusText);
                }
            }
        };
        req.onabort = req.onerror = req.ontimeout = (e) => reject(e);
        req.send('q=' + JSON.stringify(data));
      });
}

/**
 * Build an SVG scrim with color specified
 * @param {string} color 
 * @returns {object}
 */
function buildScrim(color) {
    const ns = 'http://www.w3.org/2000/svg',
        svg = createElement('svg', ns),
        circle = createElement('circle', ns);

    svg.classList.add('sbg');
    circle.classList.add('dot');
    circle.style.fill = color;
    appendTo(circle, svg);
    return { svg: svg, dot: circle };
}

/**
 * Build overlay stage with content
 * @param {number} num 
 * @returns {object}
 */
function buildStage(num) {
    if (num < 7) {
        const section = createElement('section'),
            inner = createElement('div'),
            title = createElement('h3'),
            text = createElement('div'),
            image = createElement('div'),
            footer = createElement('div'),
            footerQ = createElement('p'),
            footerRow = createElement('div'),
            buttonYes = createElement('button'),
            buttonNo = createElement('button'),
            obj = dictionaryTable[num] || {};
        section.className = 'stg stg-' + num +' out';
        text.innerHTML = '<p class="t2">' + obj.s + '</p><ul class="t6">' + obj.b.reduce(function (prev, current) { return prev + '<li>' + current + '</li>'}, '') + '</ul>';
        title.textContent = obj.t;
        title.className = 't0';
        footerQ.className = 't8';
        footerQ.textContent = obj.q;
        buttonYes.innerText = dictionaryTable[0].y;
        buttonNo.textContent = dictionaryTable[0].n;
        buttonYes.addEventListener('click', eventAddSection);
        buttonNo.addEventListener('click', eventAddSection);
        buttonYes.setAttribute('disabled','disabled');
        buttonNo.setAttribute('disabled','disabled');
        buttonYes.className = 'btn flx wbg bc' + num;
        buttonNo.className = 'btn flx sec';
        footerRow.className = 't10 dfx';
        image.className = 'fx1';
        image.innerHTML = '<div class="irn imn" style="background-image: url(' + sectionImages[num-1][1].default + ')"></div><div class="iic imn" style="background-image: url(' + sectionImages[num-1][0].default + ')"></div>';
        inner.className = 'sti';
        appendTo(buttonYes, footerRow);
        appendTo(buttonNo, footerRow);
        appendTo(footerQ, footer);
        appendTo(footerRow, footer);
        appendTo(title, inner);
        appendTo(image, inner);
        appendTo(text, inner);
        appendTo(footer, inner);
        appendTo(inner, section);
        return {r: section, y: buttonYes, n: buttonNo};
    }
    if (num === 7) {
        return buildForm();
    }
    if (num === 8) {
        const ns = 'http://www.w3.org/2000/svg',
              section = createElement('section'),
              inner = createElement('div'),
              svg = createElement('svg', ns),
              path = createElement('path', ns),
              header = createElement('h3'),
              body = createElement('div'),
              footer = createElement('div');
        section.className = 'stg stg-8 out';
        inner.className = 'sti';
        svg.setAttribute('viewBox', '0 0 600 600');
        svg.setAttribute('class', 'spn pd out');
        svg.innerHTML = '<g class="spo"><circle class="spi" cx="300" cy="300" r="285"></circle></g><circle class="dt" cx="300" cy="300" r="300"></circle>';
        path.setAttribute('class','mk');
        header.className = 't2';
        body.className = 't6';
        footer.className = 't8';
        appendTo(path, svg);
        appendTo(svg, inner);
        appendTo(header, inner);
        appendTo(body, inner);
        appendTo(footer, inner);
        appendTo(inner, section);
        return {r: section, s: svg, p: path, h: header, b: body, f: footer};
    }
}

/**
 * Measure the device screen width height and diagonal
 */
function measureScreen() {
    screenRect.width = window.innerWidth;
    screenRect.height = window.innerHeight;
    screenRect.diagonal = Math.pow(Math.pow(screenRect.width, 2) + Math.pow(screenRect.height, 2), 0.5);
    scrims.forEach(function (scrim) {
        if(!scrim || !scrim.svg) return;
        scrim.svg.setAttribute('viewBox', '0 0 ' + screenRect.width + ' ' + screenRect.height);
        scrim.dot.setAttribute('r', screenRect.diagonal);
        scrim.dot.setAttribute('cx', 0);
        scrim.dot.setAttribute('cy', 0);
    });
    initHero();
}

/**
 * Set size of hero image
 */
function initHero() {
    hero.style.width = '1px';
    const parent = hero.parentElement;
    const rect = parent.getBoundingClientRect();
    const ratio = 856 / 1151;
    var imgWidth = ratio * (rect.height - 60);
    if (rect.height < 190) imgWidth = ratio * 130;
    if (imgWidth > rect.width - 60) imgWidth = rect.width - 60;
    hero.style.width = imgWidth + 'px';
}

/**
 * Hero image loaded
 */
async function eventLoaded() {
    const heroLoaded = await loadImage(isWebPSupported ? heroImage.webp.default : heroImage.jpg.default);
    hero.src = heroLoaded.src;
    initHero();
    await loadImage(sectionImages[0][0].default);
    hero.classList.add('on');
    loadImage(sectionImages[0][1].default);
}

/**
 * Append an event with ID and timestamp to event list
 * @param {string} type Event type ID
 * @param {string} data Optional data
 */
function addEvent(type, data) {
    const ev = { t: type, s: Math.floor((new Date()).getTime() / 1000) };
    if(data) ev.d = data;
    // Append event data with timestamp
    events.push(ev);
}

/**
 * Initiate walkthrough
 * @param {MouseEvent} e 
 */
function beginBuild(e) {
    addEvent('st');
    appendTo(progress, document.body);
    enterStage(e.pageX, e.pageY);
    setTimeout(function () {
        progress.style.opacity = 1;
    }, 1000);
}

/**
 * Returns next stage number in sequence
 * @returns {number}
 */
function getNextStage() {
    step++;
    return (step < stageSquence.length) ? stageSquence[step] : step;
}

/**
 * Initiate next step of walkthrough
 * @param {number} pageX 
 * @param {number} pageY 
 */
function enterStage(pageX, pageY) {
    sendEvents();
    const idx = stageNum;
    stageNum = getNextStage();
    const scrim = scrims[stageNum];
    document.body.insertBefore(scrim.svg, progress);
    pageY = Math.min(pageY, screenRect.height);
    scrim.dot.setAttribute('cx', pageX);
    scrim.dot.setAttribute('cy', pageY);
    scrim.dot.style.transformOrigin = pageX + 'px ' + pageY + 'px';
    stages[stageNum] = buildStage(stageNum);
    document.body.insertBefore(stages[stageNum].r, progress);
    setProgress(step, dictionaryTable[stageNum].h.toUpperCase());

    // Animate stage in
    const id = stageNum;
    setTimeout(function () {
        stages[id].r.classList.remove('out');
    }, 1000);
    // Prevent clickthrough until buttons visible
    setTimeout(function () {
        if(stages[id].y) stages[id].y.removeAttribute('disabled');
        if(stages[id].n) stages[id].n.removeAttribute('disabled');
    }, 2500);

    // Remove previous Scrim and Stage
    if(stageNum > 1) {
        setTimeout(function () {
            try {
            document.body.removeChild(scrims[idx].svg);
            document.body.removeChild(stages[idx].r);
            } catch(e) {}
            scrims[idx] = stages[idx] = null;
        }, 2500);
    }

    // Load next image
    if(stageNum < sectionImages.length) {
        loadImage(sectionImages[stageNum][0].default);
        loadImage(sectionImages[stageNum][1].default);
    }
}

/**
 * Build a progress bar returning editable elements
 * @returns {Array<HTMLElement>}
 */
function buildProgress() {
    const progress = createElement('header'),
        progressTitle = createElement('h4'),
        progressLeft = createElement('div'),
        progressRight = createElement('div'),
        progressBar = createElement('div');
    progress.className = 'prg';
    progressBar.className = 'pbar';
    appendTo(progressLeft, progressBar);
    appendTo(progressRight, progressBar);
    appendTo(progressTitle, progress);
    appendTo(progressTitle, progress);
    appendTo(progressBar, progress);
    progressTitle.addEventListener('animationend', eventProgressAnimationEnd);
    return [progress, progressTitle, progressLeft, progressRight];
}

/**
 * Update the progress bar
 * @param {number} step 
 * @param {number} title 
 */
function setProgress(step, title) {
    const remain = 8 - step;
    progressLeft.style.flex = step;
    progressRight.style.flex = remain;
    if(progressTitle.textContent != '' && title != progressTitle.textContent) {
        // Animate incoming title
        progressTitle.dataset.next = title;
        progressTitle.classList.add('out');
    } else {
        // Replace title without animation
        progressTitle.textContent = title;
    }
}

/**
 * Handle progress ber title animation
 */
function eventProgressAnimationEnd() {
    if(progressTitle.dataset.next) {
        progressTitle.textContent = progressTitle.dataset.next;
        progressTitle.dataset.next = '';
        progressTitle.classList.remove('out');
        progressTitle.classList.add('in');
    } else {
        progressTitle.classList.remove('in');
    }
}

/**
 * Yes or No selected for step
 * @param {MouseEvent} e 
 */
function eventAddSection(e) {
    const isYes = e.target === stages[stageNum].y;
    stages[stageNum].y.removeEventListener('click', eventAddSection);
    stages[stageNum].n.removeEventListener('click', eventAddSection);
    addEvent('nx');
    requestData[stageNum] = { r: isYes };
    enterStage(e.pageX, e.pageY);
}


/**
 * Submit events to API
 */
function sendEvents() {
    if(!apiToken || events.length < 1) return;
    
    const data = new FormData();
    data.append('q', JSON.stringify({ id: apiToken, e: events }));
    navigator.sendBeacon(endpoint, data);
    events.length = 0;
}

/**
 * Build form page
 * @returns {object}
 */
function buildForm() {
    const section = createElement('section'),
          inner = createElement('div'),
          title = createElement('h3'),
          form = createElement('form'),
          p = createElement('p'),
          button = createElement('button'),
          group = createElement('div'),
          labels = [],
          obj = dictionaryTable[7] || {};
    section.className = 'stg stg-7 out t0';
    inner.className = 'sti';
    title.textContent = obj.t;
    form.noValidate = true;
    form.addEventListener('submit', handleSubmit);
    form.addEventListener('change', handleChange);
    p.textContent = obj.s;
    button.textContent = obj.b;
    button.className = 'btn wbg bc7';
    button.id = 'I6';
    group.className = "fg";
    appendTo(button, group);
    appendTo(buildFormInput('I1', 'Name', formData.I1), form);
    appendTo(buildFormInput('I2', obj.la, formData.I2, 1, obj.lc), form);
    appendTo(p, form);
    appendTo(buildFormRadio([obj.la, obj.lb], 'I3', ['A', 'B'], (formData.I3B) ? 1 : 0), form);
    appendTo(buildFormInput('I4', obj.lb, formData.I4, (formData.I3B) ? 1 : 0, 0, 0, 0, labels), form);
    requestData.forEach(function (obj, i) {
        if(!obj || !obj.r) return;
        const inputs = dictionaryTable[i] || {};
        appendTo(buildFormInput('R' + i, inputs.l, formData['R' + i], 0, inputs.e, inputs.p, inputs.i), form);
    });
    appendTo(buildFormInput('I5', 'Comments', formData.I5, 0, obj.e, 0, 1), form);
    appendTo(group, form);
    appendTo(title, inner);
    appendTo(form, inner);
    appendTo(inner, section);
    return {r: section, p: labels[0], l: labels[1], f: form};
}

/**
 * Creates an input element
 * @param {string} id 
 * @param {string} text 
 * @param {boolean} isRequired 
 * @param {string} help 
 * @param {string} placeholder 
 * @param {boolean} isTextArea 
 * @param {Array} refs 
 * @returns {HTMLElement}
 */
function buildFormInput(id, text, value, isRequired, help, placeholder, isTextArea, refs) {
    const group = createElement('div'),
          label = createElement('label'),
          input = (isTextArea) ? createElement('textarea') : createElement('input'),
          small = (help) ? createElement('small') : null;
    group.className = "fg";
    label.className = "lb";
    label.setAttribute('for', id);
    text += (isRequired) ? " " + dictionaryTable[0].r : " " + dictionaryTable[0].o;
    label.textContent = text;
    input.id = id;
    input.className = "tin";
    input.required = isRequired;
    value = value || "";
    if(isTextArea) {
        input.rows = 5;
        input.setAttribute('maxlength',500);
        input.innerText = value;
    } else {
        input.setAttribute('maxlength',100);
        input.value = value;
    }
    if(placeholder) {
        input.placeholder = placeholder;
    }
    appendTo(label, group);
    appendTo(input, group);
    if(small) {
        small.textContent = help;
        appendTo(small, group);
    }
    if(refs) {
        refs.push(label);
        refs.push(input);
    }
    return group;
}

/**
 * Builds a radio input group
 * @param {string[]} text 
 * @param {string} name 
 * @param {string[]} value 
 * @returns 
 */
function buildFormRadio(text, name, value, checked = 0) {
    if(!text) text = [];
    if(!value) value = [];
    const group = createElement('div');
    group.className = "fg";
    value.forEach(function (v, i) { appendTo(buildFormRadioInput(text[i], name, name + v, v, i === checked), group) });
    return group;
}

/**
 * Builds a single radio input element
 * @param {string} text 
 * @param {string} name 
 * @param {string} id 
 * @param {string} value 
 * @param {boolean} isChecked 
 * @returns {HTMLElement}
 */
function buildFormRadioInput(text, name, id, value, isChecked) {
    const group = createElement('div'),
          input = createElement('input'),
          label = createElement('label');
    group.className = "rad";
    input.type = "radio";
    input.checked = isChecked;
    input.name = name;
    input.id = id;
    input.value = value;
    label.setAttribute('for', id);
    label.textContent = text;
    appendTo(input, group);
    appendTo(label, group);
    return group;
}

/**
 * Handle input change event
 * @param {Event} e 
 */
function handleChange(e) {
    const input = e.target;
    addEvent('ch');
    if(input.type == "radio") {
        stages[7].p.textContent = dictionaryTable[7].lb + " " + ((input.value == "A") ? dictionaryTable[0].o : dictionaryTable[0].r);
        stages[7].l.required = input.value != "A";
    }
}

/**
 * Handle form submit event
 * @param {SubmitEvent} e 
 * @returns {Promise<boolean>}
 */
async function handleSubmit(e) {
    e.preventDefault();
    const form = stages[7].f,
          button = form.elements.I6,
          rect = button.getBoundingClientRect(),
          pageX = rect.left + rect.width / 2,
          pageY = rect.top + rect.height / 2;
    
    // Iterate over the form controls
    for (let i = 0; i < form.elements.length; i++) {
        if (form.elements[i].nodeName === "BUTTON" || (form.elements[i].type === "radio" && !form.elements[i].checked)) continue;
        formData[form.elements[i].id] = form.elements[i].value;
    }
    
    if(!apiToken) {
        // Retry token
        await fetchToken();
    }
    if(!apiToken) {
        showFinal(form, pageX, pageY);
        setTimeout(function () { handleComplete(false) }, 500);
        return false;
    }
    const valid = form.checkValidity(),
          data = {};
    
    if (valid) {
        addEvent('sb');
        // Disable button
        button.setAttribute('disabled', 'disabled');
        data.s = { r: requestData };
        data.e = events;
        data.id = apiToken;
        // Iterate over the form controls
        for (let i = 0; i < form.elements.length; i++) {
            if (form.elements[i].nodeName === "BUTTON" || (form.elements[i].type === "radio" && !form.elements[i].checked)) continue;
            data.s[form.elements[i].id] = form.elements[i].value;
            formData[form.elements[i].id] = form.elements[i].value;
        }
        
        // Submit data to API
        showFinal(form, pageX, pageY);
        getHttpRequest(data).then(function(result) { handleComplete(result == 'OK')}).catch(function(e) { handleComplete(false)});
        events.length = 0;
        return true;
    } else {
        // Scroll to first element that is invalid
        const node = (function() {for (let i = 0; i < form.elements.length; i++) {
            if (form.elements[i].checkValidity && form.elements[i].checkValidity() === false) return form.elements[i];
        }})();
        stages[7].r.scrollTo({
            top: (node) ? (node.offsetTop - 50) : 0,
            behavior: 'smooth',
        });
        if(node) node.focus();
    }
    return valid;
}

/**
 * Remove form listeners
 * @param {HTMLElement} form 
 */
function tidyForm(form) {
    form.removeEventListener('submit', handleSubmit);
    form.removeEventListener('change', handleChange);
}

/**
 * Display final step
 * @param {HTMLElement} form 
 * @param {number} pageX 
 * @param {number} pageY 
 */
function showFinal(form, pageX, pageY) {
    // Show spinner during submit
    tidyForm(form);
    enterStage(pageX, pageY);
    submittedTime = performance.now();

    setTimeout(function() { 
        stages[8].s.classList.remove('out'); 
    }, 1500);
}

/**
 * Handle completion event
 * @param {boolean} success 
 */
function handleComplete(success) {
    const max = 1500,
          delay = Math.min(performance.now() - submittedTime, 1499);

    // Track linkedin conversion
    // if(cConsent) window.lintrk('track', { conversion_id: 1689753 });
    
    setTimeout(function() {
        if(success) {
            // Populate path and animate in
            stages[8].p.setAttribute('d', pathdata.s);
            stages[8].s.classList.remove('pd');

            // Populate Header & body
            stages[8].h.textContent = dictionaryTable[8].ts;
            dictionaryTable[8].s.forEach(function(text) {
                const p = createElement('p');
                p.textContent = text;
                appendTo(p, stages[8].b);
            });

            // Populate Footer and attach actions
            const link = createElement('a');
            link.className = 'btn flx wbg bc8';
            link.textContent = dictionaryTable[0].f;
            link.href = 'https://www.animatedesigns.com/';
            appendTo(link, stages[8].f);
        } else {
            // Populate path and animate in
            stages[8].p.setAttribute('d', pathdata.f);
            stages[8].s.classList.remove('pd');

            // Populate Header
            stages[8].h.textContent = dictionaryTable[8].tf;

            // Populate Footer and attach actions
            const button = createElement('button'),
                  link = createElement('a');
            button.className = 'btn flx wbg bc8';
            button.textContent = dictionaryTable[0].t;
            button.addEventListener('click', handleRetry);
            link.className = 'btn flx sec';
            link.href = 'https://www.animatedesigns.com/#Contact';
            link.textContent = dictionaryTable[0].c;
            appendTo(button, stages[8].f);
            appendTo(link, stages[8].f);
        }
    }, max - delay);
}

/**
 * Handle retry event
 * @param {Event} e 
 */
function handleRetry(e) {
    // Remove listeners
    e.target.removeEventListener('click', handleRetry);

    // Remove overlays
    document.body.removeChild(progress);
    document.body.removeChild(scrims[8].svg);
    document.body.removeChild(stages[8].r);
    
    // Reset key values
    stageNum = 6;
    step = 6;
    // requestData.length = 0;
    if(!apiToken) fetchToken();
    for (let i = 1; i < 9; i++) scrims[i] = buildScrim(colors[i]);
    measureScreen();
    addEvent('rt');
    
    // Trigger click on primary button
    beginBuild(e);
}

/**
 * Helper function to append node to parent
 * @param {HTMLElement} node 
 * @param {HTMLElement} parent 
 */
function appendTo(node, parent) {
    parent.appendChild(node);
}

/**
 * Feature detect and show upgrade info
 * @returns {boolean} if required features are present
 */
function hasFeatures() {
    const undef = undefined,
          style = (buildBtn) ? buildBtn.style || {} : {};
    return style['transformOrigin'] !== undef &&
           style['transition'] !== undef &&
           style['animation'] !== undef &&
           style['flex'] !== undef &&
           buildBtn['classList'] !== undef &&
           !!window.addEventListener &&
           !!window.Promise &&
           !!window.XMLHttpRequest;
}

/**
 * Test if webP images are supported
 * @returns {Promise<boolean>}
 */
 async function testWebp() {
    try {
        const image = await loadImage('data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA');
        return image.width === 1;
    } catch(e) {
    }
    return false;
}

/**
 * Async load an image
 * @param {string} url 
 * @returns {Promise<HTMLImageElement>}
 */
 function loadImage(url) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onabort = img.onerror = (e) => { reject(e); }
        img.onload = () => resolve(img);
        img.src = url;
    });
}

/**
 * Init Linkedin partner ID and inject tracking
 */
function initLinkedin() {
    const _linkedin_partner_id = "1788673";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    
    // Show consent
    cConsent = getCookie('cc');
    const consentContainer = createElement('div');
    if(cConsent === null) {
        const btnContainer = createElement('div'),
              acceptBtn = createElement('button'),
              rejectBtn = createElement('button');
        
        consentContainer.className = 'cc';
        consentContainer.innerHTML = 'We use cookies to better understand our audience and improve our service to you. Our <a href="https://www.animatedesigns.com/cookies">cookie policy</a>. ';
        btnContainer.className = 'cbts';
        acceptBtn.className = rejectBtn.className = 'btn lte';
        acceptBtn.textContent = 'Accept';
        rejectBtn.textContent = 'Reject';
        appendTo(acceptBtn, btnContainer);
        appendTo(rejectBtn, btnContainer);
        appendTo(btnContainer, consentContainer);
        appendTo(consentContainer, document.body);
        acceptBtn.addEventListener('click', () => onConsent(true, consentContainer));
        rejectBtn.addEventListener('click', () => onConsent(false, consentContainer));
    } else {
        onConsent(cConsent === "1", consentContainer);
    }
}

function onConsent(accepted, consentContainer) {
    setCookie('cc', accepted ? "1" : "0");
    consentContainer.className = 'cc hide';
    cConsent = accepted;
    if(accepted) {
        injectLinkedin();
    }
}

function injectLinkedin() {
    (
        function(l){
            if(!l){
                window.lintrk=function(a,b){
                    window.lintrk.q.push([a,b])
                };
                window.lintrk.q=[]
            }
            var s=document.getElementsByTagName("script")[0];
            var b=document.createElement("script");
            b.type="text/javascript";
            b.async=true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);
        }
    )(window.lintrk);
}

function getCookie(name) {
    const str = `; ${document.cookie}`;
    const parts = str.split(`; ${name}=`);
    if(parts.length < 2) {
        return null;
    }
    return parts[1].split(';')[0];
}

function setCookie(name, value, days) {
    if (!days) days = 365;
    const date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    document.cookie = `${name}=${value}; expires=${date.toUTCString()};path=/;Secure;SameSite=Strict`;
}

main();